
import { defineComponent } from "@vue/runtime-core";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";

import axios from "axios";
import { Advertisers } from "@/use/enums"

type optionsKeys = "advertisers" | "geos";

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Dropdown,
    Button,
  },
  props: {
    offer: Object,
  },
  data() {
    return {
      errors: {} as { [key: string]: string | null },
      options: {
        advertisers: [],
        geos: [],
      },
      advertiser_id: null,
      commission: 20,
      loading: false,
    };
  },
  computed: {
    AdvertisersEnum() {
      return Advertisers
    }
  },
  emits: ["close", "update:offer", "saved"],
  created() {
    this.filterOptions("advertisers", "");
    this.filterOptions("geos", "");

    if (this.offer?.id) {
      axios
        .get("/api/commissions/" + this.offer?.id)
        .then((res) => (this.commission = res.data.length ? Number(res.data[0].percent) : 20));
    }
  },
  methods: {
    setCommission(value: string) {
      this.errors.percent = null;
      this.commission = Number(value);
    },
    async filterOptions(collection: optionsKeys, query = "", field?: string) {
      const { data } = await axios.get("/api/" + (field || collection) + "/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      const key: "advertisers" | "geos" = collection as optionsKeys;
      this.options[key] = data;
    },
    close() {
      this.$emit("close");
    },
    emitPatch(patch: any) {
      this.$emit("update:offer", {
        ...this.offer,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async saveOffer() {
      this.loading = true;

      try {
        const { data } = await axios.post("/api/offers", {
          ...this.offer,
          cpa_offer_id: Number(this.offer?.cpa_offer_id),
        });

        await axios.post("/api/commissions", {
          percent: this.commission,
          offer_id: this.offer?.id || data.id,
        });

        this.$emit("saved");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }

      this.loading = false;
    },
  },
});
