
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import { Pagination, Sorting } from "@/use/types";
import TextInput from "@/components/TextInput.vue";
import Tooltip from "@/components/Tooltip.vue";
import Editor from "./Editor.vue";
import DeleteOfferPopup from "./DeleteOfferPopup.vue";
import ActivateOfferPopup from "./ActivateOfferPopup.vue";
import SubsetInput from "@/components/SubsetInput.vue";

import moment from "moment-timezone";
import axios from "axios";
import { Option } from "@/use/types";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  actionsVisibleIndex: number | null;
  isRemovedPopupShowed: boolean;
  removedOfferId: number | null;
  isActivatedPopupShowed: boolean;
  activatedOfferId: number | null;
  offerToEdit: null;
  options: {
    advertisers: Option[];
    offers: Option[];
  };
}

interface IQuery {
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  removed: boolean;
  offer_ids: number[];
  advertiser_ids: number[];
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    search: "",
    sorting: { column: "name", direction: "asc" },
    pagination: { limit: 10, offset: 0 },
    removed: false,
    advertiser_ids: [],
    offer_ids: [],
  };
}

const dataColumns = [
  {
    id: "name",
    name: "Название",
    selected: true,
    sortable: true,
    freezed: true,
    type: "text",
    searchable: true,
  },
  {
    id: "cpa_offer_id",
    name: "ID в СРА",
    selected: true,
    sortable: true,
  },
  {
    id: "cpa_offer_status",
    name: "Статус",
    selected: true,
    sortable: true,
  },
  {
    id: "margin",
    name: "Маржа",
    selected: true,
    sortable: true,
  },
  {
    id: "advertiser_name",
    name: "Рекламодатель",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "geo",
    name: "Гео",
    selected: true,
    sortable: false,
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
    htmlClass: "news__table--action",
  },
];

export default defineComponent({
  name: "News",
  components: {
    AppPage,
    Button,
    DataTable,
    TextInput,
    Tooltip,
    Editor,
    DeleteOfferPopup,
    ActivateOfferPopup,
    SubsetInput,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      actionsVisibleIndex: null,
      isRemovedPopupShowed: false,
      removedOfferId: null,
      offerToEdit: null,
      activatedOfferId: null,
      isActivatedPopupShowed: false,
      options: {
        advertisers: [],
        offers: [],
      },
    };
  },
  async created() {
    this.load();
    this.getOptions("advertisers");
    this.getOptions("offers");
  },
  mounted: function () {
    this.$watch("filter.removed", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  methods: {
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get("/api/" + (field || collection) + "/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      const key: "advertisers" | "offers" = collection as any;
      this.options[key] = data;
    },
    async getOptions(collection: string) {
      const { data } = await axios.get("/api/" + collection + "/lookup");
      const key: "advertisers" | "offers" = collection as any;
      this.options[key] = data;
    },
    updateOption(collection: string, e: any) {
      const key: "advertiser_ids" | "offer_ids" = collection as any;
      this.filter[key] = e;
    },
    setActionsVisible(id: number | null) {
      if (this.actionsVisibleIndex === id) {
        this.actionsVisibleIndex = null;
      } else {
        this.actionsVisibleIndex = id;
      }
    },
    removeOffer(id: number) {
      this.isRemovedPopupShowed = true;
      this.removedOfferId = id;
      this.actionsVisibleIndex = null;
    },
    activateOffer(id: number) {
      this.isActivatedPopupShowed = true;
      this.activatedOfferId = id;
      this.actionsVisibleIndex = null;
    },
    closePopup() {
      if (this.isRemovedPopupShowed) {
        this.isRemovedPopupShowed = false;
        this.removedOfferId = null;
      }

      if (this.isActivatedPopupShowed) {
        this.isActivatedPopupShowed = false;
        this.activatedOfferId = null;
      }

      if (this.offerToEdit) {
        this.offerToEdit = null;
        document.querySelector("body")?.classList.remove("editor-opened");
      }
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    clear() {
      this.filter = getDefaultQuery();
      this.load();
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const { data } = await axios.get("/api/offers", {
          params: { q: JSON.stringify(this.filter) },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    download() {
      window.open(
        `/api/offers/download?q=${encodeURIComponent(
          JSON.stringify(this.filter)
        )}`,
        "_blank"
      );
    },
    openEditor(row: any) {
      document.querySelector("body")?.classList.add("editor-opened");

      this.offerToEdit = row || {
        name: "",
        margin: null,
        advertiser_id: null,
        geo_id: null,
        cpa_offer_id: null,
      };

      if (this.actionsVisibleIndex !== null) this.actionsVisibleIndex = null;
    },
  },
});
